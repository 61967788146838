import { useQuery } from '@tanstack/react-query';

import { handleError } from '../../../services/ErrorHandlingService';
import { Enquiry, SingleEnquiry } from '../types/enquiry';
import { ApiSearchParams, ApiSearchResult, mainApi } from '../../../api';

export const useEnquiries = (params: ApiSearchParams) =>
  useQuery({
    queryKey: ['useEnquiries', params, params?.extraData?.status],
    queryFn: async () => {
      const response = await mainApi.get<ApiSearchResult<Enquiry>>(
        '/calendar/booking/requests',
        {
          limit: params.limit,
          page: params.pageNumber,
          statuses: params?.extraData?.status,
        }
      );

      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }

      return response.data;
    },
  });

export const useSingleEnquiry = (id) =>
  useQuery({
    queryKey: ['useSingleEnquiry', id],
    queryFn: async () => {
      const response = await mainApi.get<SingleEnquiry>(
        `/calendar/booking/requests/${id}`
      );

      if (!response.ok) {
        handleError(response);
      } else {
        return response.data;
      }
    },
  });
